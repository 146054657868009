import {initializeApp} from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDhdK3ZW6VPJAV9g4r3dcBl2sh-5nDXhrs",
    authDomain: "tooler-d1af4.firebaseapp.com",
    projectId: "tooler-d1af4",
    storageBucket: "tooler-d1af4.appspot.com",
    messagingSenderId: "313151980766",
    appId: "1:313151980766:web:748caca94bbf995f08ae4f",
    measurementId: "G-Z9DTCV63P5"
  };

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app)
export const auth = getAuth(app);
