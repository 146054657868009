import { Link } from 'react-router-dom';
import logo from '../tooler.jpg';

function Header(props) {
    let right = null;

    if(props.right == "signup") {
        right = <Link to="/">
            Sign Up
        </Link>
    } else if(props.right == "login") {
        right = <Link to="/login">
        Login
    </Link>
    }

    const toggleDisplay = () => {
        var sidebar = document.getElementById('sidebar');
        sidebar.classList.toggle('active');
    }

    return (
        <div className='Logo-Login'>
          <div>
            <svg className="toggle-btn bi bi-x" onClick={() => {toggleDisplay()}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
            </svg>
            <img src={logo} />
          </div>
          {right}
      </div>
    )
}

export default Header