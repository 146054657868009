import { doc, collection, query, where, getDocs, orderBy, limit, updateDoc } from "firebase/firestore";
import { db, auth } from "../firebase";

export async function getApps() {
    const q = query(collection(db, 'apps'), where("owner", "==", auth.currentUser.uid), orderBy("time", "desc"), limit(10))
    const querySnap = await getDocs(q);
    let apps = []
    querySnap.forEach((doc) => {
        let data = doc.data();
        apps.push({"id": doc.id, "data": data})
    });
    return apps;
}

export async function updateUrl(id, url) {
    await updateDoc(doc(db, 'apps', id), {
        url: url
    });
}