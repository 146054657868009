import UserProfile from "./UserProfile";
import "../styles/Sidebar.css"
import React, { useEffect, useState } from "react";
import { getApps, updateUrl } from "../service/firebase_service";
import Files from "./Files";
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import gif from "../loading.gif"

function Sidebar(props) {

    const [url, setUrl] = useState(null)
    const [apps, setApps] = useState([])
    const [error, setError] = useState('')
    const [loading, setLoad] = useState(false)

    useEffect( () => {
        setUrl(props.app.data != null && "url" in props.app.data ? props.app.data.url : null)
    }, [props.app.data])

    const toggleDisplay = () => {
        var sidebar = document.getElementById('sidebar');
        sidebar.classList.toggle('active');
    }

    const navigateCode = (app) => {
        let newType = !props.type;
        props.changeType(newType, app)
    }

    const changeFile = (path) => {
        props.changeFile(path)
    }

    const downloadApp = () => {
        var formdata = new FormData();
        formdata.append("project_id", props.app.id);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        setLoad(true)
        fetch("https://tooler-d1af4.uc.r.appspot.com/download", requestOptions)
            .then(response => response.arrayBuffer())
            .then(buf => {
                setLoad(false)
                var blob = new Blob([buf], {type: "application/zip"});
                var file = window.URL.createObjectURL(blob);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display:none";
                a.href = file;
                a.download = props.app.data.name + ".zip";
                a.click();
                window.URL.revokeObjectURL(file);
                a.remove();
            })
            .catch(error => {
                setLoad(false)
                setError(error.message)
                setTimeout(
                    function () {
                        setError('')
                    }.bind(this), 5000);
            });
    }

    const deployApp = () => {
        var formdata = new FormData();
        formdata.append("project_id", props.app.id);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        setLoad(true)
        fetch("https://bypass-server-dot-tooler-d1af4.uc.r.appspot.com", requestOptions)
            .then(response => response.text())
            .then(async (res) => {
                setLoad(false)
                await updateUrl(props.app.id, "https://" + props.app.id + "-dot-tooler-d1af4.uc.r.appspot.com")
                setUrl("https://" + props.app.id + "-dot-tooler-d1af4.uc.r.appspot.com")
            })
            .catch(error => {
                setLoad(false)
                setError(error.message)
                setTimeout(
                    function () {
                        setError('')
                    }.bind(this), 5000);
            });
    }

    useEffect(() => {
        async function getTheApps() {
            let apps = await getApps()
            setApps(apps)
        }

        if (props.type == false) {
            getTheApps()
        }
    }, [])

    return (
        <>
            <div id="sidebar">
                <div id="sidebar-content">
                    <svg className="toggle-btn bi bi-x" onClick={() => { toggleDisplay() }} xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                    {props.type == true ?
                        <div className="sidebar-back" onClick={() => { navigateCode("") }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                            </svg>
                            <span>Go Back</span>
                        </div>
                        : <></>
                    }
                    <h5>{props.type == false ? "Your Apps" : props.app.data.name}</h5>
                    {props.type == true ? <div className="app-buttons">
                        <button class="btn btn-primary" onClick={downloadApp}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                            </svg>
                            Download</button>
                        <button class="btn btn-primary" onClick={deployApp}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-cloud-arrow-up-fill" viewBox="0 0 16 16">
                                <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 5.146a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2z" />
                            </svg>
                            Deploy</button>
                        {url != null ? <a href={url}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-link" viewBox="0 0 16 16">
                                <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z"/>
                                <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z"/>
                            </svg>
                        </a> : <></>}
                        {loading ? <img id="load-download" src={gif} /> : <></>}
                        <Files app={props.app} change={changeFile} />
                    </div> : <div></div>}
                    {
                        props.type == false &&
                        apps.map(function (app, i) {
                            return <div className="app-sidebar" id={"app-" + app.id} onClick={() => { navigateCode(app) }}>
                                <p className="text-muted">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app-indicator" viewBox="0 0 16 16">
                                        <path d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z" />
                                        <path d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                    </svg>
                                    {app.data.name}
                                </p>
                            </div>
                        })
                    }
                </div>
                <div id="sidebar-settings">
                    <UserProfile />
                </div>
            </div>
            <ToastContainer className="p-3" position="bottom-center">
                <Toast autohide={true} show={error != ''} bg="danger">
                    <Toast.Body style={{ color: "white" }}>{error}</Toast.Body>
                </Toast>
            </ToastContainer>
        </>
    );
}

export default Sidebar