import { Link, useNavigate } from "react-router-dom"
import Header from "../components/Header"
import { useState } from "react"
import "../styles/Login.css"
import { signInWithPopup, GoogleAuthProvider, signInWithEmailAndPassword} from "firebase/auth";
import { auth } from "../firebase";
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

const provider = new GoogleAuthProvider();

function Login() {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("")
    const [error, setError] = useState("")
    const navigate = useNavigate();

    const handleLogin = (e) => {
        if (!e.target.checkValidity()) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            e.preventDefault();
            e.stopPropagation();
            signInWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    navigate("/");
                })
                .catch((error) => {
                    const errorMessage = error.message;
                    setError(errorMessage)
                    setTimeout(
                        function () {
                            setError('')
                        }.bind(this), 5000);
                });
        }
        e.target.classList.add('was-validated');
    }

    const handleChange = (e) => {
        const id = e.target.id;
        if(id == "email") {
            setEmail(e.target.value)
        } else if(id == "password") {
            setPassword(e.target.value)
        }
    }

    const handleGoogle = () => {
        signInWithPopup(auth, provider)
            .then((result) => {
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                const user = result.user;
                navigate("/");
            }).catch((error) => {
                const errorMessage = error.message;
                setError(errorMessage)
                setTimeout(
                    function () {
                        setError('')
                    }.bind(this), 5000);
            });
    }

    return (
        <>
            <Header right="signup" />
            <div className="Login-Container">
                <div className="Login">
                    <h3>Sign In</h3>
                    <form id="sign-in" noValidate="" onSubmit={(e) => { handleLogin(e) }}>

                        <div class="mb-3">
                            <label class="form-label" for="email">Email</label>
                            <input class="form-control" id="email" type="email" placeholder="Email" required value={email} onChange={(e) => handleChange(e)} />
                            <div class="invalid-feedback">
                                Please provide a valid email.
                            </div>
                        </div>

                        <div class="mb-3">
                            <label class="form-label" for="password">Password</label>
                            <input class="form-control" id="password" type="password" placeholder="Password" required value={password} onChange={(e) => handleChange(e)} />
                            <div class="invalid-feedback">
                                Please provide a valid Password.
                            </div>
                        </div>


                        <div class="d-grid">
                            <button class="btn btn-primary btn-lg" id="submit-message">Submit</button>
                        </div>
                        <Link id="forgot" to="/forgotpassword">
                            Forgot Password?
                        </Link>
                        <br />
        
                        <h5 id="provider">With a Provider</h5>
                        <button id="google-button" type="button" onClick={() => {handleGoogle()}}>Sign in with Google</button>
                    </form>
                </div>
                <ToastContainer className="p-3" position="bottom-center">
                    <Toast autohide={true} show={error != ''} bg="warning">
                        <Toast.Body>{error}</Toast.Body>
                    </Toast>
                </ToastContainer>
            </div>
        </>
    )
}

export default Login