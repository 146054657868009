import React, { useState } from 'react';
import { auth } from "../firebase";

const UserProfile = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className="user-profile"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="user-info">
        <img src={auth.currentUser.photoURL} alt="User Icon" className="user-icon" />
        <span className="user-name">{auth.currentUser.displayName}</span>
      </div>
      {isHovered && (
        <button className="sign-out-button" onClick={() => {auth.signOut()}}>
          Sign Out
        </button>
      )}
    </div>
  );
};

export default UserProfile;