import React, { useState } from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import MainCode from '../components/MainCode';
import MainInput from '../components/MainInput';

function Home() {

    const [type, setType] = useState(false);
    const [app, setApp] = useState("")
    const [file, setFile] = useState("")

    const changeTypeHandler = (newType, app) => {
        setType(newType);
        setApp(app);
    };

    const changeFileHandler = (file) => {
        setFile(file)
    }

    return (
        <div className="App">
            <Header />
            <div id="container">
                <Sidebar type={type} changeType={changeTypeHandler} app={app} changeFile={changeFileHandler} />
                <div id="main-content">
                    {type == true ? <MainCode app={app} file={file} changeType={changeTypeHandler} /> : <MainInput changeType={changeTypeHandler} />}
                </div>
                
            </div>
        </div>
    )
}

export default Home