import './App.css';
import React, { useState } from "react";
import { auth } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ForgotPassword from './pages/ForgotPassword';
import Home from './pages/Home';
import Login from './pages/Login';
import 'bootstrap/dist/css/bootstrap.min.css';
import Signup from './pages/Signup';

function App() {
  const [component, setComponent] = useState(0);
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      if(component != "pages") {
        setComponent(
          "pages"
        );
      }
    } else {
      if(component != "login") {
        setComponent(
          "login"
        );
      }
    }
  });
  return (
    component == "pages" ?
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
        </Routes>
      </BrowserRouter> : 
      component == "login" ?
      <BrowserRouter>
        <Routes>
          <Route index element={<Signup auth={auth} />} />
          <Route path="login" element={<Login auth={auth} />} />
          <Route path="forgotpassword" element={<ForgotPassword  auth={auth} />} />
          <Route path="*" element={<Login auth={auth} />} />
        </Routes>
      </BrowserRouter>
      :
      <br />
  );
}

export default App;
