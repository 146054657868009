import React, { useState } from "react"
import gif from "../loading.gif"
import design from "../design.svg"
import { auth } from "../firebase"
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import "../styles/Input.css"

const texts = [
    "It will take between 1-2 mins...",
    "Creating project...",
    "Designing the application...",
    "Writing some code ...",
    "Writing tests...",
    "This is taking longer than expected",
    "Hold in there now..."
]

function MainInput(props) {

    const [loading, setLoading] = useState(false)
    const [prompt, setPrompt] = useState("")
    const [step, setStep] = useState(0)
    const [error, setError] = useState('')

    const createApp = () => {
        setLoading(true)
        const intervalID = setInterval(changeStep, 30000);
        
        var myHeaders = new Headers();
        var formdata = new FormData();
        formdata.append("user_text", prompt);
        formdata.append("owner", auth.currentUser.uid);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://tooler-d1af4.uc.r.appspot.com/", requestOptions)
            .then(response => response.json())
            .then(result => {
                clearInterval(intervalID)
                setLoading(false)
                let app = {}
                app.data = {}
                app.data.name = result.name
                app.data.file_structure = result.file_structure
                app.data.repo = result.repo;
                app.id = result.id
                props.changeType(true, app)
            })
            .catch(error => {
                clearInterval(intervalID)
                setError(error.message)
                setLoading(false)
                setTimeout(
                    function () {
                        setError('')
                    }.bind(this), 5000);
            });
    }

    const changeStep = () => {
        if(step + 1 < 7) {
            const newStep = step + 1
            setStep(newStep)
        }
    }

    return (
        <>
            { loading == true ? <div id="loading-content">
                <img src={gif} />
                <p className="text-muted">{texts[step]}</p>
            </div> :
            <div id="input-with-image">
                <div className="design-image">
                    <img src={design} />
                </div>
                <div id="input-content">
                    <input type="text" id="search-box" name="q" placeholder="What would you like to create today?" autofocus value={prompt} onChange={(e) => {setPrompt(e.target.value)}} />
                    <div id="buttons">
                        <input type="button" id="search-button" value="Create App" onClick={() => {createApp()}} />
                    </div>
                </div>
            </div>
            }
            <ToastContainer className="p-3" position="bottom-center">
                <Toast autohide={true} show={error != ''} bg="danger">
                    <Toast.Body style={{color: "white"}}>{error}</Toast.Body>
                </Toast>
            </ToastContainer>
        </>

    )
}

export default MainInput