import React, { useState } from 'react';
import '../styles/Login.css';
import { sendPasswordResetEmail } from 'firebase/auth';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Header from '../components/Header';

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = { email: '', message: '', type: 'warning' };

        this.handleReset = this.handleReset.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleReset(e) {
        if (!e.target.checkValidity()) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            e.preventDefault();
            e.stopPropagation();
            sendPasswordResetEmail(this.props.auth, this.state.email)
                .then(() => {
                    this.setState({
                        message: "Password reset email sent!",
                        type: 'success'
                    })
                    setTimeout(
                        function() {
                            this.setState({
                                message: ''
                            })
                        }.bind(this), 5000);
                })
                .catch((error) => {
                    const errorMessage = error.message;
                    this.setState({
                        message: errorMessage,
                        type: 'warning'
                    })
                    setTimeout(
                        function() {
                            this.setState({
                                messsage: ''
                            })
                        }.bind(this), 5000);
                });
        }

        e.target.classList.add('was-validated');
    }

    handleChange(e) {
        const id = e.target.id;
        this.setState({
            [id]: e.target.value
        })
    }

    render() {
        return (
            <div>
                <Header />
                <div className="Login-Container">
                    <div className="Login">
                        <Row>
                            <Col sm={9}>
                        <h3>Forgot Password</h3>
                        <form id="sign-in" noValidate="" onSubmit={this.handleReset}>

                            <div class="mb-3">
                                <label class="form-label" for="email">Email</label>
                                <input class="form-control" id="email" type="email" placeholder="Email" required value={this.state.email} onChange={this.handleChange} />
                                <div class="invalid-feedback">
                                    Please provide a valid email.
                                </div>
                            </div>


                            <div class="d-grid">
                                <button class="btn btn-primary btn-lg" id="submit-message">Submit</button>
                            </div>

                        </form>
                        </Col>
                        </Row>

                        <ToastContainer className="p-3" position="bottom-center">
                            <Toast autohide={true} show={this.state.message != ''} bg={this.state.type}>
                                <Toast.Body>{this.state.message}</Toast.Body>
                            </Toast>
                        </ToastContainer>
                    </div>
                </div>
            </div>
        );
    }
}

export default ForgotPassword;