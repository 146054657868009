import React, { useState } from "react";
import { CopyBlock, paraisoLight } from "react-code-blocks";
import waiting from "../waiting.svg"
import "../styles/Code.css"
import gif from "../loading.gif"
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

function MainCode(props) {

    const extToLanguage = {
        "py": "python",
        "html": "html",
        "css": "html",
        "js": "jsx"
    }

    const [prompt, setPrompt] = useState("")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const obj = props.app.data.file_structure.find(file => { return file.file == props.file });

    const update = () => {
        setLoading(true)

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "project_id": props.app.id,
            "user_text": prompt,
            "file_structure": props.app.data.file_structure
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://tooler-d1af4.uc.r.appspot.com/update", requestOptions)
            .then(response => response.json())
            .then(result => {
                setLoading(false)
                let app = {}
                app.id = props.app.id
                app.data = {}
                app.data.name = props.app.data.name
                app.data.file_structure = result.file_structure
                app.data.repo = result.repo
                props.changeType(true, app)
            })
            .catch(error => {
                setError(error.message)
                setLoading(false)
                setTimeout(
                    function () {
                        setError('')
                    }.bind(this), 5000);
            });
    }

    return (
        <>
            {
                loading ? <div id="loading-content">
                    <img src={gif} />
                    <p className="text-muted">You know the drill ...</p>
                </div> :
                    <>
                        <div id="update-content">
                            <div id="code-content">
                                {
                                    obj != null ?
                                        <div className="code-and-update">
                                            <CopyBlock
                                                text={obj.contents}
                                                showLineNumbers={true}
                                                theme={paraisoLight}
                                                language={!(obj.file.split(".").splice(-1) in extToLanguage) ? "" : extToLanguage[obj.file.split(".").splice(-1)]}
                                            />
                                        </div>
                                        :
                                        <img className="waiting-image" src={waiting} />
                                }
                            </div>
                            <div className="update-field">
                                <input type="text" id="search-box" name="q" placeholder="What would you like to change?" autofocus value={prompt} onChange={(e) => { setPrompt(e.target.value) }} />
                                <div id="buttons">
                                    <input type="button" id="search-button" value="Update App" onClick={update} />
                                </div>
                            </div>
                        </div>
                        <ToastContainer className="p-3" position="bottom-center">
                            <Toast autohide={true} show={error != ''} bg="danger">
                                <Toast.Body style={{ color: "white" }}>{error}</Toast.Body>
                            </Toast>
                        </ToastContainer>
                    </>
            }
        </>
    )
}

export default MainCode;