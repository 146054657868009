import React, {useState} from "react";

function convertStructure(name, input) {
    const output = { folder: name, folders: [], files: [] };
  
    input.forEach(({ folder, files }) => {
      const path = folder.split('/');
      let currentDir = output;
  
      if(path[0] != "root") {
        path.forEach((dir, index) => {
            const existingDir = currentDir.folders.find(({ folder }) => folder === dir);
    
            if (existingDir) {
                currentDir = existingDir;
            } else {
                const newDir = { folder: dir, folders: [], files: [] };
                currentDir.folders.push(newDir);
                currentDir = newDir;
            }
    
            if (index === path.length - 1) {
                currentDir.files = files;
            }
        });
      } else {
        currentDir.files = files
      }
    });
  
    return output;
  }

function Files(props) {
    const outputStructure = convertStructure(props.app.data.name, props.app.data.repo);

    const changeFile = (path) => {
        props.change(path.substring(1))
    }

    return (
        <div className="folder-structure">
            <FolderStructure folder={outputStructure} name={props.app.data.name} route="" changeFile={changeFile} />
        </div>
    )
}

const FolderStructure = ({ folder, name, route, changeFile }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleClick = (file) => {
        let path = route + "/" + folder.folder + "/" + file;
        path = path.replace(name, "root")
        if(path.split("/").length > 3) {
            path = path.replace("/root", "")
        }
        changeFile(path)
    }

    return (
        <ul className="folder" id={folder.folder == name ? "root-folder" : folder.folder}>
            <li className="folder-name" id={folder.folder == name ? "root-folder-name" : folder.folder + "-name"} onClick={handleToggle}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-chevron-right sidebar-right-icon ${isOpen ? 'rotate-icon' : ''}`} viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-folder-fill sidebar-folder-icon" viewBox="0 0 16 16">
                    <path d="M.54 3.87.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31zM2.19 4a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4H2.19zm4.69-1.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707z"/>
                </svg>
                {folder.folder}
            </li>
            {
                folder.folders.length != 0 &&
                <ul className={`folders ${isOpen ? '' : 'folders-hidden'}`}>
                    {folder.folders.map(subFolder => (
                    <li key={subFolder.folder}>
                        <FolderStructure folder={subFolder} name={name} route={route + "/" + folder.folder} changeFile={changeFile} />
                    </li>
                    ))}
                </ul>
            }
            {
                folder.files.length != 0 &&
                <ul className={`files ${isOpen ? '' : 'files-hidden'}`}>
                    {folder.files.map(file => (
                        <li key={file} onClick={() => {handleClick(file)}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-fill sidebar-file-icon" viewBox="0 0 16 16">
                                <path d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3z"/>
                            </svg>
                            {file}    
                        </li>
                    ))}
                </ul>
            }
        </ul>
    );
}

export default Files;